<div class="page-register flex flex-row">
    <div class="left-part w-2/5 flex flex-col justify-between">
        <img class="w-full h-2/3" src="../../../assets/img/login.svg" alt="">
        <div class="left-word text-base font-medium lineheight-150">Unlock
            <span class="left-word-bold"> effortless shopping</span>
            at your own pace
        </div>
    </div>

    <div class="right-part w-3/5 flex flex-col gap-8">
        <div class="flex flex-row justify-between w-full">
            <div>
                <p-button *ngIf="canGoback()" icon="pi pi-arrow-left" [rounded]="true" severity="secondary"
                    (onClick)="gotoLoginPage()" [text]="true"></p-button>
            </div>

            <p-button icon="pi pi-times" [rounded]="true" severity="secondary" (onClick)="closeWindow()"
                [text]="true"></p-button>
        </div>
        <div class="mt-2" [ngSwitch]="step">
            <ng-container *ngSwitchCase="'loginEmailStep'">
                <ng-content *ngTemplateOutlet="loginEmailStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'loginStep'">
                <ng-content *ngTemplateOutlet="loginStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'registerStep'">
                <ng-content *ngTemplateOutlet="registerStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'resetPasswordEmailStep'">
                <ng-content *ngTemplateOutlet="resetPasswordEmailStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'resetPasswordStep'">
                <ng-content *ngTemplateOutlet="resetPasswordStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'verifyStep'">
                <ng-content *ngTemplateOutlet="verifyStep"></ng-content>
            </ng-container>
            <ng-container *ngSwitchCase="'sessionExpiredStep'">
                <ng-content *ngTemplateOutlet="sessionExpiredStep"></ng-content>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #loginEmailStep>
    <div class="flex flex-col gap-8">
        <div class="right-title ">Log in or Sign up</div>

        @if(isIncorrectBuyerType()){ <div class="message">
            <i class="pi pi-info-circle info-icon"></i>
            <div class="message-text">
                <div class="message-title">Email already signed up as seller</div>
                <div class="message-error-info">This email
                    is already linked to a seller account. Please use a
                    different email.</div>
            </div>
        </div>
        }

        <div class="flex flex-col gap-2">
            <div class="inputLabel">Email</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getEmailClass()"
                [formControl]="getEmailFrnCnt()" (ngModelChange)="setLoginEmail()" (focusout)="checkEmailValid()" />
            <small class="errorInfo" *ngIf="hasEmailError()">{{getEmailErrorInfo()}}
            </small>
        </div>
        <p-button label="Continue with email" (onClick)="checkEmail()" [rounded]="true" [style]="{width:'100%'}"
            [size]="'small'" severity="contrast" [disabled]="emailInValid()"></p-button>

        <p-divider layout="horizontal" [align]="'center'">or</p-divider>


        <p-button (onClick)="signInFromGoogle()" [rounded]="true" [style]="{width:'100%'}" [outlined]="true"
            severity="secondary" size="small">
            <div class="flex flex-row items-center justify-center gap-4 w-full">
                <img src="../../../assets/img/google.png" width="17" height="17">
                <div class="continue-btn">Continue with Google</div>
            </div>
        </p-button>
        <!-- <p-button (onClick)="signInFromFacebook()" [rounded]="true" [style]="{width:'100%'}" [outlined]="true"
            severity="secondary" size="small">
            <div class="flex flex-row items-center gap-4 justify-center w-full">
                <img src="../../../assets/img/Facebook_Logo.png" width="17" height="17">
                <div class="continue-btn">Continue with Facebook</div>
            </div>
        </p-button> -->
        <div class="left-description">By continuing, you agree to AmMall’s
            <a class="link-underline"
                [routerLink]="['',{ outlets: { primary: ['legal-docs', 'TERMS'], mainBody: null } },]"
                (click)="closeWindow()">Terms & Conditions</a>
            and
            <a class="link-underline"
                [routerLink]="['',{ outlets: { primary: ['legal-docs', 'PRIVACY'], mainBody: null } },]"
                (click)="closeWindow()">Privacy Policy</a>
            .
        </div>

    </div>
</ng-template>

<ng-template #loginStep>
    <div class="flex flex-col gap-8 ">
        <div>
            <div class="right-title">Log in</div>
            <div class="mt-2 inputLabel">Welcome back!</div>
        </div>
        @if(isIncorrectPassword()){ <div class="message">
            <i class="pi pi-info-circle info-icon"></i>
            <div class="message-text">
                <div class="message-title">Incorrect email or password</div>
                <div class="message-error-info">Please double-check your information and try again.</div>
            </div>
        </div>
        }
        <div class="flex flex-col gap-2">
            <div class="inputLabel">Email</div>
            <div class="flex flex-row gap-1m items-center justify-between">
                <div class="inputLabel login-email-text">{{getLoginEmail()}}</div>
                <p-button label="Change" (onClick)="toNextStep('loginEmailStep')" [text]="true" size="small"></p-button>
            </div>
        </div>
        <form>
        <input type="email" id="email" hidden/>
        <amm-password [passwordFrmCnt]="getPasswordFrnCnt()" [entryConfig]="{label:'Password',noFeedback: true,showError:false}"
            (passwordChanged)="renewPassword()"></amm-password>
        </form>
        <!-- <div class="flex flex-row gap-2 items-center justify-between">
            <div class="flex flex-row gap-2 items-center">
                <p-checkbox [(ngModel)]="stayLoggedin" [binary]="true" inputId="binary" [disabled]="true" />
                <div class="inputLabel stay-logged-text">Stay logged in</div>
            </div>
            <p-button [style]="{width:'200px'}" label="Forgot password" (onClick)="toNextStep('resetPasswordEmailStep')"
                [text]="true" size="small"></p-button>
        </div> -->
        <p-button class="flex self-end" label="Forgot password" (onClick)="toNextStep('resetPasswordEmailStep')"
            [text]="true" size="small">
        </p-button>

        <p-button label="Log in" (onClick)="login()" [rounded]="true" [style]="{width:'100%'}" [size]="'small'"
            severity="contrast"></p-button>
        <p-divider layout="horizontal" [align]="'center'">or</p-divider>

        <div class="flex flex-col gap-2">
            <div class="flex flex-col justify-content-end gap-4">
                <p-button class="continue-google-section" (onClick)="signInFromGoogle()" [rounded]="true"
                    [style]="{width:'100%'}" [outlined]="true" severity="secondary" size="small">
                    <div class="flex flex-row items-center gap-4">
                        <img src="../../../assets/img/google.png" width="17" height="17">
                        <div class="continue-btn">Continue with Google</div>
                    </div>
                </p-button>
                <!-- <p-button (onClick)="signInFromFacebook()" [rounded]="true" [style]="{width:'100%'}" [outlined]="true"
                    severity="secondary" size="small">
                    <div class="flex flex-row items-center gap-4">
                        <img src="../../../assets/img/Facebook_Logo.png" width="17" height="17">
                        <div class="continue-btn">Continue with Facebook</div>
                    </div>
                </p-button> -->
            </div>

        </div>
    </div>
</ng-template>


<ng-template #registerStep>
    <div class="flex flex-col gap-8">
        <div class="right-title">Create a new account</div>

        <div class="flex flex-col gap-2">
            <div class="inputLabel">Email</div>
            <div class="flex flex-row gap-1m items-center justify-between">
                <div class="inputLabel">{{getLoginEmail()}}</div>
                <p-button label="Change" (onClick)="toNextStep('loginEmailStep')" [text]="true" size="small"></p-button>
            </div>
        </div>
        <div class="flex flex-col gap-2">
            <div class="inputLabel">Your name</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getNameInputClass()"
            (ngModelChange)="regestInputChange()"
                [formControl]="getUserNameFrmCnt()" />
            <small class="errorInfo" *ngIf="hasNameError()">{{getUserNameErrorInfo()}}
            </small>
        </div>
        <amm-password [passwordFrmCnt]="getPasswordFrnCnt()" [entryConfig]="{label:'Password'}"></amm-password>
        <p-button class="button" [style]="{'width':'100%'}" label="Continue" severity="contrast" [rounded]="true"
            (onClick)="sentRegisterEmail()" [disabled]="canNotRegister()" size="small">
        </p-button>
    </div>
</ng-template>

<ng-template #resetPasswordEmailStep>
    <div class="flex flex-col gap-8 ">
        <div class="flex flex-col gap-2">
            <div class="right-title">Reset password</div>
            <div class="inputLabel">We’ll send a verification code to your email.</div>
        </div>

        <div class="flex flex-col gap-2">
            <div class="inputLabel">Email</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getEmailClass()"
                [formControl]="getEmailFrnCnt()" (ngModelChange)="setResetPasswordEmail()" />
            <small class="errorInfo" *ngIf="hasEmailError()">{{getResetPasswordEmailErrorInfo()}}
            </small>
        </div>
        <p-button label="Continue" (onClick)="sendResetPasswordEmail()" [rounded]="true" [style]="{width:'100%'}"
            [size]="'small'" severity="contrast" [disabled]="emailInValid()"></p-button>
    </div>
</ng-template>

<ng-template #resetPasswordStep>
    <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-2">
            <div class="right-title">Set a new password</div>
            <div class="sub-title">Set new password for {{getLoginEmail()}}.</div>
        </div>

        <form class="flex flex-col gap-3" [formGroup]="formGroupFormControl">
            <amm-password [passwordFrmCnt]="getGroupPasswordFrnCnt()"
                [entryConfig]="{label:'New password',error:passwordNotMatch()}"></amm-password>
            <amm-password [passwordFrmCnt]="getGroupConfirmPasswordFrnCnt()"
                [entryConfig]="{label:'Re-enter password', noFeedback: true,error:passwordNotMatch()}">
            </amm-password>
            <small class="errorInfo" *ngIf="passwordNotMatch()">
                Password and Re-enter password not matching!
            </small>
        </form>


        <div class="flex flex-row justify-between">
            <div></div>
            <p-button class="button" [style]="{'width':'100%'}" label="Start shopping" severity="contrast"
                [rounded]="true" (onClick)="resetPassword()" [disabled]="canBeReset()">
            </p-button>
        </div>

    </div>
</ng-template>

<ng-template #verifyStep>
    <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-1">
            <div class="right-title">{{getVerifyPageTitle()}}</div>
            <div class="inputLabel">We’ve sent a {{resendCodeHasNew()}} code to {{getLoginEmail()}}.</div>
        </div>
        @if(isExpiredCode){ <div class="message">
            <i class="pi pi-info-circle info-icon"></i>
            <div class="message-text">
                <div class="message-title">The code you entered has expired.</div>
                <div class="message-error-info">Please provide the new verification code we’ve sent to you.</div>
            </div>
        </div>
        }
        <div class="flex flex-col gap-2">
            <div class="inputLabel">Verification code</div>
            <input [ngStyle]="{'width':'100%'}" autofocus pInputText [ngClass]="getCodeClass()"
                (ngModelChange)="setVerigyCode()" [formControl]="getCodeFrnCnt()"
                [placeholder]="'enter 6-digit code here'" />
            <small class="errorInfo" *ngIf="hasCodeError()">{{getCodeErrorInfo()}}
            </small>
            <p-button class="self-end" AmmCountdown [label]="getResentButtonLabel()" (onClick)="reSendCode()"
                [disabled]="cannotRescentCode()" [text]="true">
            </p-button>
        </div>
        <p-button class="button" [style]="{'width':'100%'}" [label]="getVerifyButtonLabel()" severity="contrast"
            [rounded]="true" (onClick)="varifyCode()" [disabled]="codeInValid()">
        </p-button>
        @if(resentFlag){<div>
            <span class="need-help-text">Still have trouble with verification code?  </span>
            <u class="need-help-text" (click)="gotoHelpCenter()">Get help</u>
        </div>
        }
    </div>
</ng-template>
<ng-template #sessionExpiredStep>
    <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-1">
            <div class="right-title">Session expired</div>

        </div>
        <div class="message">
            <i class="pi pi-info-circle info-icon"></i>
            <div class="message-text">
                <div class="message-title">Your password reset session has timed out.</div>
                <div class="message-error-info">Please verify your email and provide your new password again.</div>
            </div>
        </div>

        <p-button class="button" [style]="{'width':'100%'}" [label]="'Back to verify email'" severity="contrast"
            [rounded]="true" (onClick)="backVerifyEmail()" [icon]="'pi pi-arrow-left'">
        </p-button>

    </div>
</ng-template>