import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { AmmPasswordComponent } from '../../amm-share/components/amm-password/amm-password.component';
import { AmmCountdownDirective } from '../../amm-share/directives/amm-countdown.directive';
import { AmmTemplateNameDirective } from '../../amm-share/directives/amm-templatename.directive';
import { UseraccountModel } from '../../amm-share/models';
import { StorageService } from '../../amm-share/services';
import { GoogleApiService } from '../../amm-share/services/google-api.service';
import { RbacService } from '../../amm-share/services/rbac.service';
import { RoutingService } from '../../amm-share/services/routing.service';
import { SseService } from '../../amm-share/services/sse.service';
import { CommonStore } from '../../amm-share/stores';
import { MessagingStore } from '../../amm-share/stores/amm-message.store';
import { HasStringValue, NgFormValidator } from '../../components.global';
import { ShoppingInfoStore } from './../../amm-share/stores/shopping.store';
import { APPCONSTANT } from '../../amm-share/constants';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'amm-page-register',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AmmPasswordComponent,
    DividerModule,
    InputTextModule,
    CheckboxModule,
    AmmTemplateNameDirective,
    AmmCountdownDirective,
  ],
  templateUrl: './page-register.component.html',
  styleUrl: './page-register.component.scss',
  providers: [GoogleApiService],
})
export class PageRegisterComponent implements OnInit {
  @ViewChild(AmmCountdownDirective)
  protected _countdownElm?: AmmCountdownDirective;
  @Input()
  step?: string;
  @Output()
  onClose = new EventEmitter<boolean>();

  private _storageService = inject(StorageService);
  private sseService = inject(SseService);

  private shoppingInfoStore = inject(ShoppingInfoStore);
  private messageStore = inject(MessagingStore);
  private commonStore = inject(CommonStore);
  private dialogConfig = inject(DynamicDialogConfig<PageRegisterComponent>);
  constructor(
    private _routingService: RoutingService,
    private _rbacService: RbacService,
    private readonly googleApi: GoogleApiService,
    public ref: DynamicDialogRef<PageRegisterComponent>,
    public dialog: DialogService
  ) { }

  userInfo?: UseraccountModel;

  stayLoggedin: boolean = false;
  currentVarifyStep: string = '';

  ngOnInit(): void {
    this.step = this.dialogConfig?.data?.step;
    if (!this.step) {
      this.step = REGISTER_STEP.LOGINEMAIL;
    }

    if (this.dialogConfig?.data?.errorInfo == 'role==seller') {
      this._isIncorrectBuyer = true;
    }
    this.firstTimeForRegisterPage=true;
  }

  resetPassword(): void {
    this._rbacService
      .resetPassword(
        this._emailFrmCnt.value || '',
        this._codeFrmCnt.value || '',
        this.formGroupFormControl.get('passwordFrmCnt')?.value || '',
      )
      .subscribe((info) => {
        if (typeof info == 'boolean') {
          if (info) {
            this._incorrectCode = false;
            this._passwordFrmCnt=this.formGroupFormControl.get('passwordFrmCnt') as FormControl;
            this.login();
          }
        } else if (info[0].error && info[0].code?.includes('400')) {
          this.step = REGISTER_STEP.SESSIONEXPIRED;
        }
      });
  }

  private _incorrectCode: boolean = false;

  hasError(): boolean {
    return this._incorrectCode;
  }

  forgetPassword(): void { }

  private _isIncorrectBuyer: boolean = false;
  isIncorrectCode: boolean = false;
  isExpiredCode: boolean = false;
  // private confirmPasswordFrmCnt: FormControl<string | null> = new FormControl(
  //   '',
  //   [
  //     NgFormValidator.required(),

  //   ]
  // );



  private _passwordFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.passwordMustHaveUppercase(
      "Let's make your password stronger! Try including at least one uppercase letter."
    ),
    NgFormValidator.passwordMustHaveLowercase(
      "Let's make your password stronger! Try including at least one lowercase letter."
    ),
    NgFormValidator.passwordMustHaveNumber(
      "Let's make your password stronger! Try including at least one numeric."
    ),
    NgFormValidator.minLength(
      8,
      "Let's make your password stronger! Try including a minimum of 8 characters."
    ),
  ]);
  private _userNameFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.required(),
    NgFormValidator.maxLength(64, 'Your name must less than 64 charactors'),
  ]);
  getUserNameFrmCnt(): FormControl {
    return this._userNameFrmCnt;
  }
  getPasswordFrnCnt(): FormControl {
    return this._passwordFrmCnt;
  }



  // getComfirmPasswordError(): boolean {
  //   if (!HasStringValue(this.confirmPasswordFrmCnt.value)) {
  //     return false;
  //   }
  //   return this._passwordFrmCnt.value == this.confirmPasswordFrmCnt.value
  //     ? false
  //     : true;
  // }

  ////////////////////////////share part/////////////////////////////////
  toNextStep(st: string): void {
    this.step = st;
  }

  signInFromFacebook(): void { }

  signInFromGoogle(): void {
    this.googleApi.initGoogleAPI();
    this.googleApi.signin();
  }

  hasEmailError(): boolean {
    return (
      this._isIncorrectBuyer ||
      this.newEmailForResetPassword ||
      (this.emailChecked && HasStringValue(this._emailFrmCnt.value))
    ); //没填的时候不检查。
  }
  emailInValid(): boolean {
    return this._emailFrmCnt.invalid;
  }
  checkEmailValid():void{
    this.emailChecked=this._emailFrmCnt.invalid;
  }

  canGoback(): boolean {
    return (
      this.step == REGISTER_STEP.RESETPASSWORD ||
      this.step == REGISTER_STEP.SESSIONEXPIRED ||
      this.step == REGISTER_STEP.RESETPASSWORDEMAIL ||
      (this.step == REGISTER_STEP.VERIFY &&
        this.currentVarifyStep == REGISTER_STEP.RESETPASSWORD)
    );
  }

  gotoLoginPage(): void {
    this.step = REGISTER_STEP.LOGIN;
  }
  ///////////////////////////1. login email step/////////////////////////
  checkEmail(): void {
    this._rbacService
      .checkEmail(this._emailFrmCnt.value || '')
      .subscribe((has) => {
        if (!has.exists) {
          this.step = REGISTER_STEP.REGISTER;
        } else if (has.roles == 'buyer') {
          this.step = REGISTER_STEP.LOGIN;
        } else {
          this._isIncorrectBuyer = true;
        }
      });
  }

  setLoginEmail(): void {
    this._isIncorrectBuyer = false;
    this.emailChecked=false;
  }
  emailChecked=false;
  getEmailErrorInfo(): string {
    return this._isIncorrectBuyer
      ? // ? 'waiting the error info from designer'
      ''
      : "That doesn't look like a valid email address. Please try again.";
  }
  isIncorrectBuyerType(): boolean {
    return this._isIncorrectBuyer;
  }
  isIncorrectPassword(): boolean {
    return this.isIncorrectPass;
  }

  private _emailFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.required(),
    NgFormValidator.email('Not an email address!'),
  ]);

  getEmailFrnCnt(): FormControl {
    return this._emailFrmCnt;
  }

  getLoginEmail(): string {
    return this._emailFrmCnt.value || '';
  }

  getEmailClass(): string {
    return this._isIncorrectBuyer || this.newEmailForResetPassword
      ? 'ng-invalid ng-dirty'
      : '';
  }
  isIncorrectPass: boolean = false;
  login(): void {
    this._rbacService
      .login(this._emailFrmCnt.value || '', this._passwordFrmCnt.value || '')
      .subscribe((info) => {
        if (!info || info?.error) {
          if (info?.code && info.code.includes('401')) {
            this.isIncorrectPass = true;
          }
        } else {

          this.userInfo = info;
          this._storageService.setLocalItem(
            APPCONSTANT.BUYER_INFO,
            JSON.stringify(this.userInfo)
          );
          this.shoppingInfoStore.loadAllFavorites();
          this.shoppingInfoStore.loadShoppingCarts();
          const jwt = this._storageService.getJWT();
          if (!this.sseService.isConnected && jwt) {
            this.sseService.connectToSSE(jwt);
          }
          this.messageStore.loadConversations();
          this.commonStore.loadBuyerInfo();
          this.ref.close();
          
        }
      });
  }
  renewPassword(): void {
    this.isIncorrectPass = false;
  }
  instance: DynamicDialogComponent | undefined;
  closeWindow(): void {
    this.ref.close();
  }

  getNameInputClass(): string {
    return this._userNameFrmCnt.invalid ? 'ng-invalid ng-dirty' : '';
  }

  //////////////////////////2.2 register step//////////////////////////
  getUserNameErrorInfo(): string {
    if (this._userNameFrmCnt?.errors != null) {
      return this._userNameFrmCnt?.errors['errorMessage'];
    }
    return '';
  }
  firstTimeForRegisterPage=true;
  hasNameError(): boolean {
    if (this.firstTimeForRegisterPage){
      return false;
    }
    return this._userNameFrmCnt.invalid || false;
  }
  regestInputChange():void{
    this.firstTimeForRegisterPage=false;
  }
  canNotRegister(): boolean {
    return !(this._userNameFrmCnt.valid && this._passwordFrmCnt.valid);
  }
  sentRegisterEmail(): void {
    this.currentVarifyStep = REGISTER_STEP.REGISTER;
    this.userInfo = {
      nickName: this._userNameFrmCnt.value || '',
      password: this._passwordFrmCnt.value || '',
      loginName: this._emailFrmCnt.value || '',
    };
    // this._merchantService.sendVerifyCode().subscribe();
    this._rbacService.registerStart(this.userInfo).subscribe((hasSend) => { });
    this.step = REGISTER_STEP.VERIFY; //即使没有发email成功也到此页面，在此页面resend verify code
  }
  getVerifyPageTitle(): string {
    switch (this.currentVarifyStep) {
      case REGISTER_STEP.REGISTER:
        return 'Verify your email';
      case REGISTER_STEP.RESETPASSWORD:
        return 'Reset password';
      default:
        return '';
    }
  }
  getVerifyButtonLabel(): string {
    switch (this.currentVarifyStep) {
      case REGISTER_STEP.REGISTER:
        return 'Start shopping';
      case REGISTER_STEP.RESETPASSWORD:
        return 'Continue';
      default:
        return 'Continue';
    }
  }
  getResentButtonLabel(): string {
    return 'Resend code ' + (this._countdownElm?.getLabel() || '');
  }

  cannotRescentCode(): boolean {
    return !this._countdownElm?.countdownFinish();
  }

  resentFlag = false;
  reSendCode(): void {
    this.resentFlag = true;
    this.isExpiredCode = false;
    if (this.currentVarifyStep == REGISTER_STEP.REGISTER) {
      this.sentRegisterEmail();
    } else {
      this.sendResetPasswordEmail();
    }
  }
  setVerigyCode(): void {
    this.isExpiredCode = false;
    this.isIncorrectCode = false;
  }

  varifyCode(): void {
    if (this.currentVarifyStep == REGISTER_STEP.REGISTER) {
      this._rbacService
        .registerComplete(
          this._emailFrmCnt.value || '',
          this._codeFrmCnt.value || ''
        )
        .subscribe((response) => {
          if (typeof response == 'boolean') {
            if (response) {
              this.login();
              this.ref.close();

            }
          } else if (response[0].error) {
            const errorCode = response[0]?.code;
            if (errorCode?.includes('400')) {
              this.isExpiredCode = true;
            } else if (errorCode?.includes('401')) {
              this.isIncorrectCode = true;
            }
          }
        });
    } else {
      this._rbacService
        .codeVerify(this._emailFrmCnt.value || '', this._codeFrmCnt.value || '')
        .subscribe((response) => {
          if (response.dataList) {
            const success = response.dataList[0] as boolean;
            if (success) {
              this.step = REGISTER_STEP.RESETPASSWORD;
            } else {
              this.isIncorrectCode = true;
            }
          } else if (response.statusList && response.statusList[0]?.error) {
            this.isExpiredCode = true;
          }
        });
    }
  }

  gotoHelpCenter(): void {
   
    this._routingService.gotoUrl('help-center-article/004_Account%20&%20Settings/Not%20receiving%20emails%20from%20AmMall');
    this.closeWindow()
  }

  private _codeFrmCnt: FormControl<string | null> = new FormControl('', [
    NgFormValidator.required(),
    NgFormValidator.fixedLengthDigit(6, 'The code is 6 digits'),
    // NgFormValidator.otherError(this.isIncorrectCode, 'Code doesn\'t match. Check your email for the correct 6-digit verification code and try again.'),
  ]);
  getCodeFrnCnt(): FormControl {
    return this._codeFrmCnt;
  }
  hasCodeError(): boolean {
    return (
      (this._codeFrmCnt.invalid && HasStringValue(this._codeFrmCnt.value)) ||
      this.isIncorrectCode
    ); //没填的时候不检查。
  }
  codeInValid(): boolean {
    return this._codeFrmCnt.invalid || this.isIncorrectCode;
  }

  getCodeErrorInfo(): string {
    if (this._codeFrmCnt?.errors != null) {
      return this._codeFrmCnt?.errors['errorMessage'];
    }

    return "Code doesn't match. Check your email for the correct 6-digit verification code and try again.";
  }

  getCodeClass(): string {
    return this.isIncorrectCode || this._codeFrmCnt.invalid
      ? 'ng-invalid ng-dirty'
      : '';
  }

  ////////////////////////4. reset password//////////////
  newEmailForResetPassword: boolean = false;
  sendResetPasswordEmail(): void {
    //此处新增对输入的邮箱进行验证，是否存在，如果不存在，跳到注册步骤 2024-09-05
    //因设计修改此处如邮箱不存在，改成错误提示，不跳转 2024-11-19
    this._rbacService
      .checkEmail(this._emailFrmCnt.value || '')
      .subscribe((has) => {
        if (!has.exists) {
          // this.step = REGISTER_STEP.REGISTER;
          this.newEmailForResetPassword = true;
        } else if (has.roles == 'buyer') {
          this.currentVarifyStep = REGISTER_STEP.RESETPASSWORD;
          this._rbacService
            .emailCode({ loginName: this._emailFrmCnt.value || '' })
            .subscribe((hasSend) => {
              if (hasSend) {
                this.step = REGISTER_STEP.VERIFY;
              } else {
                this._canNotSentEmail = true;
              }
            });
        } else {
          this._isIncorrectBuyer = true;
        }
      });
  }


  formGroupFormControl = new FormGroup(
    {
      passwordFrmCnt: new FormControl('', [
        NgFormValidator.passwordMustHaveUppercase(
          "Let's make your password stronger! Try including at least one uppercase letter."
        ),
        NgFormValidator.passwordMustHaveLowercase(
          "Let's make your password stronger! Try including at least one lowercase letter."
        ),
        NgFormValidator.passwordMustHaveNumber(
          "Let's make your password stronger! Try including at least one numeric."
        ),
        NgFormValidator.minLength(
          8,
          "Let's make your password stronger! Try including a minimum of 8 characters."
        ),
      ]),
      confirmPasswordFrmCnt: new FormControl(
        '',
        [
          NgFormValidator.required(),

        ]
      )
    },
    { validators: NgFormValidator.shouldMatch('passwordFrmCnt', 'confirmPasswordFrmCnt') } // 这里加上自定义验证器
  );
  getGroupConfirmPasswordFrnCnt(): FormControl {
    return this.formGroupFormControl.get('confirmPasswordFrmCnt') as FormControl;
  }
  getGroupPasswordFrnCnt(): FormControl {
    return this.formGroupFormControl.get('passwordFrmCnt') as FormControl;
  }
  passwordNotMatch(): boolean {
    return HasStringValue(this.formGroupFormControl.get('passwordFrmCnt')?.value)
      && HasStringValue(this.formGroupFormControl.get('confirmPasswordFrmCnt')?.value)
      && this.formGroupFormControl.hasError('passwordsNotMatching');
  }
  resendCodeHasNew(): string {
    return this.resentFlag ? 'new' : '';
  }
  canBeReset(): boolean {
    if (
      HasStringValue(this.formGroupFormControl.get('passwordFrmCnt')?.value) &&
      this.formGroupFormControl.get('passwordFrmCnt')?.value == this.formGroupFormControl.get('confirmPasswordFrmCnt')?.value
    ) {
      return false;
    }
    return true;
  }
  setResetPasswordEmail(): void {
    this.newEmailForResetPassword = false;
    this._canNotSentEmail = false;
  }
  private _canNotSentEmail: boolean = false;
  getResetPasswordEmailErrorInfo(): string {
    if (this.newEmailForResetPassword) {
      return 'We couldn’t find an account with this email.';
    }
    if (this._canNotSentEmail) {
      return 'Something wrong.';
    }
    if (this._isIncorrectBuyer) {
      return 'This email is a seller account. Please use a different email.';
    }
    return "That doesn't look like a valid email address. Please try again.";
  }
  backVerifyEmail(): void {
    this.step = REGISTER_STEP.RESETPASSWORDEMAIL;
  }
}

export const REGISTER_STEP = {
  RESETPASSWORD: 'resetPasswordStep',
  RESETPASSWORDEMAIL: 'resetPasswordEmailStep',
  REGISTER: 'registerStep',
  LOGIN: 'loginStep',
  LOGINEMAIL: 'loginEmailStep',
  VERIFY: 'verifyStep',
  RESENDCODE: 'resendCodeStep',
  SESSIONEXPIRED: 'sessionExpiredStep',
};
