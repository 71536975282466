
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AmmTextEntryData, HasStringValue, NgFormValidator } from '../../../components.global';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'amm-password',
  standalone: true,
  imports: [
    PasswordModule, 
    DividerModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './amm-password.component.html',
  styleUrls: ['./amm-password.component.scss']
})
export class AmmPasswordComponent implements OnInit {

  @Input()
  passwordFrmCnt: FormControl<string | null> = new FormControl('', NgFormValidator.passwordLimitation());;

  @Input()
  entryConfig?: AmmTextEntryData;

  @Output()
  passwordChanged = new EventEmitter<string>();

  component() { }

  ngOnInit(): void {

  }

  noFeedback(): boolean {
    return this.entryConfig?.noFeedback || false;
  }
  getPasswordFrnCnt(): FormControl {
    return this.passwordFrmCnt;
  }

  getErrorInfo(): string {
    if (this.passwordFrmCnt?.errors) {
      return this.passwordFrmCnt?.errors['errorMessage'];
    }
    if (this.entryConfig?.errorMsg) {
      return this.entryConfig?.errorMsg;
    }
    return '';
    // return 'something wrong';
  }
  passwordEmit(): void {
    this.passwordChanged.emit(this.passwordFrmCnt.value || '');
  }

  hasError(): boolean {
    if (!this.entryConfig?.showError){
      return false;
    }
    return (this.passwordFrmCnt.invalid && HasStringValue(this.passwordFrmCnt.value)) || (this.entryConfig?.error || false);
  }

  getLabel(): string {
    return this.entryConfig?.label || '';
  }
  getPasswordClass(): string {
    return this.hasError() ? 'password ng-invalid ng-dirty' : 'password';
  }
  getPlaceHolder(): string {
    return this.entryConfig?.placeholder || '';
  }

}